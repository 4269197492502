<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon (click)="back()">keyboard_backspace</mat-icon>
    </button>

    <span class="title-text h1">
      {{profileData.title}} {{profileData.first_name}} {{profileData.middle_name}} {{profileData.last_name}}
    </span>
  </div>


  <form fxLayout="column" [formGroup]="Form" name="Form">
    <div class="white-box">

      <div class="profHeader p20 bb1">

        <h4>Credentails</h4>



        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-1" fxFlex="25%">
            <p class="dLabel">User Name / Email</p>
            <p class="dValue">{{profileData.email}}</p>
          </div>
          <div class="item item-2" fxFlex="25%">
            <p class="dLabel">Old Password</p>
            <p class="dValue">
              <mat-form-field floatLabel="never">
                <input matInput autocomplete="off" type="password" formControlName="old_password">
              </mat-form-field>
            </p>
          </div>
          <div class="item item-3" fxFlex="25%">
            <p class="dLabel">New Password</p>
            <p class="dValue">
              <mat-form-field floatLabel="never">
                <input matInput autocomplete="off" type="password" formControlName="new_password">

                <mat-error *ngIf="Form.get('new_password').hasError('minlength')">
                  Minimum 6 characters required!
                </mat-error>

              </mat-form-field>
            </p>
          </div>
          <div class="item item-3" fxFlex="25%">
            <p class="dLabel">Confirm Password</p>
            <p class="dValue">
              <mat-form-field floatLabel="never">
                <input matInput autocomplete="off" type="password" formControlName="confirm_password">

                <mat-error *ngIf="Form.get('confirm_password').hasError('minlength')">
                  Minimum 6 characters required!
                </mat-error>
                <mat-error *ngIf="
                !Form.get('confirm_password').hasError('required') &&
                Form.get('confirm_password').hasError('passwordsNotMatching')
              ">
                  Passwords must match
                </mat-error>
              </mat-form-field>
            </p>
          </div>
        </div>


        <h4>Personal Information</h4>

        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-2" fxFlex="20%">
            <p class="dLabel">Title*</p>
            <p>

              <mat-select formControlName="title">
                <mat-option value="">Select Tilte</mat-option>
                <mat-option value="Mr">Mr</mat-option>
                <mat-option value="Mrs">Mrs</mat-option>
                <mat-option value="Ms"> MS</mat-option>
                <mat-option value="Dr">Dr</mat-option>
              </mat-select>
              <mat-error *ngIf="Form.get('title').hasError('required')">
                Title is required!
              </mat-error>
            </p>
          </div>
          <div class="item item-2" fxFlex="20%">
            <p class="dLabel">Name</p>
            <p class="dValue">
              {{profileData.first_name}} {{profileData.middle_name}} {{profileData.last_name}} </p>
          </div>

          <div class="item item-2" fxFlex="20%">
            <p class="dLabel">Skype ID*</p>
            <p class="dValue">
              <mat-form-field floatLabel="never">
                <input matInput placeholder="Skype ID" formControlName="skype_id" autocomplete="off">
                <mat-error *ngIf="Form.get('skype_id').hasError('required')">
                  Skype id is required!
                </mat-error>
              </mat-form-field>
            </p>
          </div>
          <div class="item item-3" fxFlex="20%">
            <p class="dLabel">Phone -1</p>
            <p class="dValue">
              <mat-form-field floatLabel="never">
                <input matInput placeholder="Phone -1" formControlName="phone" autocomplete="off">
                <mat-error *ngIf="Form.get('phone').hasError('required')">
                  Phone id is required!
                </mat-error>

              </mat-form-field>
            </p>
          </div>
          <div class="item item-4" fxFlex="20%">
            <p class="dLabel">Phone -2</p>
            <p class="dValue">
              <mat-form-field floatLabel="never">
                <input matInput placeholder="Phone -2" formControlName="phone_sec" autocomplete="off">
              </mat-form-field>
            </p>
          </div>

        </div>

        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">

          <div class="item item-4" fxFlex="33%">
            <mat-form-field >
              <mat-label class="font-size-12">Gender</mat-label>
              <mat-select placeholder="Select Gender" formControlName="gender">
                <mat-option value="">Select</mat-option>
                <mat-option value=1>Male</mat-option>
                <mat-option value=2>Female</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="item item-2" fxFlex="33%">
            <mat-form-field>
              <mat-label class="font-size-12">Date of Birth</mat-label>
              <input matInput (click)="dp2.open()" [matDatepicker]="dp2" formControlName="dob">
              <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="item item-3" fxFlex="33%">
            <mat-form-field >
              <mat-label class="font-size-12">Timezone</mat-label>
              <mat-select formControlName="timezone">
                <mat-option [value]="''">Select</mat-option>
                <mat-option *ngFor="let timezones of timezoneData.data" [value]="timezones.zone">
                   {{timezones.country_name}} {{timezones.zone}} ({{timezones.gmt_offset}})
                </mat-option>
              </mat-select>   
            </mat-form-field>  
          </div>
        </div>

        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-2" fxFlex="100%">
            <p class="dLabel">Address*</p>
            <p class="dValue">
              <mat-form-field floatLabel="never" class="dBlock">
                <input matInput placeholder="Address" formControlName="address1">
                <mat-error *ngIf="Form.get('address1').hasError('required')">
                  Address is required!
                </mat-error>
              </mat-form-field>
            </p>
          </div>
        </div>

        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">

          <div class="item item-4" fxFlex="25%">
            <p class="dLabel">Country*</p>
            <p class="dValue">
              <mat-form-field floatLabel="never">
                <input matInput placeholder="Country" formControlName="country" autocomplete="off">
                <mat-error *ngIf="Form.get('country').hasError('required')">
                  Country is required!
                </mat-error>
              </mat-form-field>
            </p>
          </div>

          <div class="item item-2" fxFlex="25%">
            <p class="dLabel">City*</p>
            <p class="dValue">
              <mat-form-field floatLabel="never">
                <input matInput placeholder="City" formControlName="city" autocomplete="off">
                <mat-error *ngIf="Form.get('city').hasError('required')">
                  City is required!
                </mat-error>
              </mat-form-field>
            </p>
          </div>
          <div class="item item-3" fxFlex="25%">
            <p class="dLabel">State/Province</p>
            <p class="dValue">
              <mat-form-field floatLabel="never">
                <input matInput placeholder="State/Province" formControlName="state" autocomplete="off">

              </mat-form-field>
            </p>
          </div>
          <div class="item item-4" fxFlex="25%">
            <p class="dLabel">Postal Code*</p>
            <p class="dValue">
              <mat-form-field floatLabel="never">
                <input matInput placeholder="Postal Code" formControlName="postal_code" autocomplete="off">
                <mat-error *ngIf="Form.get('postal_code').hasError('required')">
                  Postal code is required!
                </mat-error>
              </mat-form-field>
            </p>
          </div>



        </div>




        <div class="container mb-20" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
          fxLayoutGap.xs="0">

          <div class="item item-1" fxFlex="auto">
            <div class="profImage">
              <img [src]="vendor_file_data" onerror="this.src='assets/images/avatars/profile.jpg'" width="120" /><br />
              <button mat-stroked-button (click)="onFileUpload()">Upload Image</button>
              <input type="file" id="imgUpload" name="imgUpload" style="display:none;" />
              <div fxLayout="row" fxLayoutAlign="center center">
                <mat-error *ngIf="fileError !== ''">{{fileError}}</mat-error>
                <span style="color: green;">{{fileSuccess}}</span>
              </div>

            </div>


          </div>
        </div>

      </div>

      <div class="boxBody">
        <div class="boxBodyInn">
          <h4>Notification Setting</h4>
          <mat-checkbox  formControlName="sms_notification" [value]="1" (change)="smsNotification($event)">SMS</mat-checkbox>
          <mat-checkbox  formControlName="wtsp_notification" [value]="1" (change)="wtspNotification($event)">WhatsApp</mat-checkbox>


          <h4>Translation Service</h4>
          <div class="lanPirInfo">
            <div class="content">
              <!-- <h2 class="aHead" *ngIf="profileData.vendornativelanguage!=null">
                Native in <span class="txt-green" *ngIf="profileData.vendornativelanguage!=null">
                {{profileData.vendornativelanguage.data.lang_name}}</span></h2> -->
              <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex="20">
                  <mat-label class="font-size-12">Native Language*</mat-label>
                  <mat-select formControlName="native_language_id">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.id">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="Form.get('native_language_id').hasError('required')">
                    Native Language is required!
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-table [dataSource]="dataSource" class="tableGrey bdr1">
                <!-- Position Column -->
                <!-- Name Column -->
                <ng-container matColumnDef="langPair">
                  <mat-header-cell *matHeaderCellDef> Language Pair </mat-header-cell>
                  <mat-cell *matCellDef="let lang_combination">
                    {{lang_combination.sourcelanguage.data.lang_name }} {{lang_combination.bi_direction}}
                    {{lang_combination.targetlanguage.data.lang_name}}
                  </mat-cell>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="translationRate">
                  <mat-header-cell *matHeaderCellDef> Translation Rate (USD) </mat-header-cell>
                  <mat-cell *matCellDef="let lang_combination"> {{lang_combination.translation_rate}} </mat-cell>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="proofindRate">
                  <mat-header-cell *matHeaderCellDef> Proofreading Rate (USD) </mat-header-cell>
                  <mat-cell *matCellDef="let lang_combination"> {{lang_combination.proofreading_rate}} </mat-cell>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="dtpRate">
                  <mat-header-cell *matHeaderCellDef> Dtp Rate (USD) </mat-header-cell>
                  <mat-cell *matCellDef="let lang_combination"> {{lang_combination.dtp_rate}} </mat-cell>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="qaRate">
                  <mat-header-cell *matHeaderCellDef> Qa Rate (USD) </mat-header-cell>
                  <mat-cell *matCellDef="let lang_combination"> {{lang_combination.qa_rate}} </mat-cell>
                </ng-container>
                <!-- Symbol Column -->
                <ng-container matColumnDef="speed">
                  <mat-header-cell *matHeaderCellDef> Speed </mat-header-cell>
                  <mat-cell *matCellDef="let lang_combination"> {{lang_combination.speed}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="stage_id == 1">Actions</span>
                    <span *ngIf="stage_id != 1"></span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let lang_combination" fxHide fxShow.gt-xs>
                    <button mat-stroked-button class="bicon" (click)="editData(lang_combination)" *ngIf="stage_id == 1">
                      <mat-icon matTooltip="Click here to Edit" *ngIf="stage_id == 1">
                        edit
                      </mat-icon>
                    </button>

                    <!-- <mat-icon (click)="editData(lang_combination)" class="mr-16" matTooltip="Edit" >
                            edit
                        </mat-icon> -->
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let lang_combination; columns: displayedColumns;"></mat-row>
              </mat-table>

              <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                *ngIf="stage_id == 1">
                <mat-form-field fxFlex="16">
                  <mat-label class="font-size-12">Source Language*</mat-label>
                  <mat-select [(ngModel)]="data.source_lang_id" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="getSourceName($event)">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let res of languageData" [value]="res.id">
                      {{res.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="16">
                  <mat-label class="font-size-12">Target Language*</mat-label>
                  <mat-select [(ngModel)]="data.target_lang_id" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="getTargetName($event)">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let language_res of languageData" [value]="language_res.id">
                      {{language_res.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="12">
                  <mat-label class="font-size-12">Translation Rate* </mat-label>
                  <input matInput type="number" [(ngModel)]="data.translation_rate"
                    [ngModelOptions]="{standalone: true}">
                  <mat-hint class="mt-8">USD Per Word</mat-hint>
                </mat-form-field>
                <mat-form-field fxFlex="12">
                  <mat-label class="font-size-12">Proofreading Rate*</mat-label>
                  <input matInput type="number" [(ngModel)]="data.proofreading_rate"
                    [ngModelOptions]="{standalone: true}">
                  <mat-hint class="mt-8">USD Per Word</mat-hint>
                </mat-form-field>

                <mat-form-field fxFlex="8">
                  <mat-label class="font-size-12">Dtp Rate*</mat-label>
                  <input matInput type="number" [(ngModel)]="data.dtp_rate" [ngModelOptions]="{standalone: true}">
                  <mat-hint class="mt-16">USD Per Word</mat-hint>
                </mat-form-field>

                <mat-form-field fxFlex="8">
                  <mat-label class="font-size-12">Qa Rate*</mat-label>
                  <input matInput type="number" [(ngModel)]="data.qa_rate" [ngModelOptions]="{standalone: true}">
                  <mat-hint class="mt-16">USD Per Word</mat-hint>
                </mat-form-field>
                <mat-form-field fxFlex="8">
                  <mat-label class="font-size-12">Speed</mat-label>
                  <input matInput type="number" [(ngModel)]="data.speed" [ngModelOptions]="{standalone: true}">
                  <mat-hint class="mt-8">Per Day</mat-hint>
                </mat-form-field>
                <mat-checkbox [(ngModel)]="data.bi_direction" [value]="data.bi_direction"
                  [ngModelOptions]="{standalone: true}" class="mt-8">Vice-versa</mat-checkbox>
                <button mat-mini-fab color="primary" type="button" (click)="addLanguageTableApi()"> Save</button>
              </div>

              <h4>Avt</h4>

              <mat-table [dataSource]="dataSubtitlerSource" class="tableGrey bdr1">
                <!-- Position Column -->
                <!-- Name Column -->
                <ng-container matColumnDef="langPair">
                  <mat-header-cell *matHeaderCellDef>Language Pair </mat-header-cell>
                  <mat-cell *matCellDef="let subtitler_lang_combination">
                    {{subtitler_lang_combination.sourcelanguage.data.lang_name }}
                    {{subtitler_lang_combination.bi_direction}}
                    {{subtitler_lang_combination.targetlanguage.data.lang_name}} </mat-cell>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef="service">
                  <mat-header-cell *matHeaderCellDef> Service </mat-header-cell>
                  <mat-cell *matCellDef="let subtitler_lang_combination"> {{subtitler_lang_combination.skillservice.data.name}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="subtitlerRate">
                  <mat-header-cell *matHeaderCellDef> Rate (USD) </mat-header-cell>
                  <mat-cell *matCellDef="let subtitler_lang_combination"> {{subtitler_lang_combination.subtitle_rate}}
                  </mat-cell>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="speed">
                  <mat-header-cell *matHeaderCellDef> Speed* </mat-header-cell>
                  <mat-cell *matCellDef="let subtitler_lang_combination"> {{subtitler_lang_combination.speed}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="stage_id == 1">Actions</span>
                    <span *ngIf="stage_id != 1"></span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let subtitler_lang_combination" fxHide fxShow.gt-xs>
                    <button mat-stroked-button class="bicon" (click)="editSubtitlerData(subtitler_lang_combination)"
                      *ngIf="stage_id == 1">
                      <mat-icon matTooltip="Click here to Edit" *ngIf="stage_id == 1">
                        edit
                      </mat-icon>
                    </button>
                    &nbsp;
                    <button mat-stroked-button class="bicon" (click)="deleteSubtitlerData(subtitler_lang_combination,i)"
                      *ngIf="stage_id == 1">
                      <mat-icon matTooltip="Click here to Delete" *ngIf="stage_id == 1">
                        delete
                      </mat-icon>
                    </button>

                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedSubtitlerColumns"></mat-header-row>
                <mat-row *matRowDef="let subtitler_lang_combination; columns: displayedSubtitlerColumns;"></mat-row>
              </mat-table>

              <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"
                *ngIf="stage_id == 1">
                <mat-form-field fxFlex="16">
                  <mat-label class="font-size-12">Source Language*</mat-label>
                  <mat-select [(ngModel)]="dataSubtitler.source_lang_id" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="getSubtitlerSourceName($event)">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let res of languageData" [value]="res.id">
                      {{res.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="16">
                  <mat-label class="font-size-12">Target Language*</mat-label>
                  <mat-select [(ngModel)]="dataSubtitler.target_lang_id" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="getSubtitlerTargetName($event)">
                    <mat-option [value]="''">
                      Select
                    </mat-option>
                    <mat-option *ngFor="let language_res of languageData" [value]="language_res.id">
                      {{language_res.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="20">
                  <mat-label class="font-size-12">Service</mat-label>
                  <mat-select [(ngModel)]="dataSubtitler.service" [ngModelOptions]="{standalone: true}">
                    <mat-option [value]="''">Select</mat-option>
                    <mat-option value="Translation">Translation</mat-option>
                    <mat-option value="Transcription">Transcription</mat-option>
                    <mat-option value="Subtitling">Subtitling</mat-option>
                    <mat-option value="Subtitling Embedding and Hardcoding">Subtitling Embedding and Hardcoding
                    </mat-option>
                    <mat-option value="Voice Over">Voice Over</mat-option>
                    <mat-option value="Engineering">Engineering</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="10">
                  <mat-label class="font-size-12">Rate* </mat-label>
                  <input matInput type="number" [(ngModel)]="dataSubtitler.subtitle_rate"
                    [ngModelOptions]="{standalone: true}">
                  <mat-hint class="mt-16">USD Per minute</mat-hint>
                </mat-form-field>

                <mat-form-field fxFlex="10">
                  <mat-label class="font-size-12">Speed</mat-label>
                  <input matInput type="number" [(ngModel)]="dataSubtitler.speed" [ngModelOptions]="{standalone: true}">
                  <mat-hint class="mt-16">Per Day</mat-hint>
                </mat-form-field>

                <div fxFlex="15" class="pt-16 text-center">
                  <mat-checkbox [(ngModel)]="dataSubtitler.bi_direction" [value]="dataSubtitler.bi_direction"
                    [ngModelOptions]="{standalone: true}" class="mt-8">Vice-versa</mat-checkbox>
                </div>
                <button mat-mini-fab color="primary" type="button" (click)="addSubtitlerLanguageTableApi()">
                  Save</button>
              </div>
              
              <h4>Terping</h4>
              <table  class="tableGrey bdr1">
                <thead>
                  <tr>
                    <th class="mat-header-cell">Language Combinations</th>
                    <th class="mat-header-cell">Service</th>
                    <th class="mat-header-cell">Qualification</th>
                    <th class="mat-header-cell">Hour Rate <span class="smlText pt-0"> Rate (USD) / hours </span></th>
                    <th class="mat-header-cell">Minute Rate <span class="smlText pt-0"> Rate (USD) / minutes </span></th>
                    <!-- <th>Speed <span class="smlText pt-0"> Words / Day</span></th> -->
                    <th class="mat-header-cell">Flexible rate <span class="smlText pt-0"> </span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let trepinglang of response_data?.vendorTreping?.data">

                    <td class="mat-cell" style="border: none;">
                      <p *ngIf="trepinglang.treplang!=null">
                        {{trepinglang.treplang}}
                      </p>
                    </td>
                    <td class="mat-cell" style="border: none;">
                      <p *ngIf="trepinglang.trepingSkillservice.data.name!=null">{{trepinglang.trepingSkillservice.data.name}}</p>
                    </td>
                    <td class="mat-cell" style="border: none;">
                      <p *ngIf="trepinglang.qualification!=null">{{trepinglang.qualification}}</p>
                    </td>
                    <td class="mat-cell" style="border: none;">
                      <p *ngIf="trepinglang.rate_hour!=null">
                        ${{trepinglang.rate_hour}} USD Per hour
                      </p>
                    </td>
                    <td class="mat-cell" style="border: none;">
                      <p *ngIf="trepinglang.rate_min!=null">
                        ${{trepinglang.rate_min}} USD Per minute
                      </p>
                    </td>
                    <!-- <td>
                      <p *ngIf="trepinglang.speed!=null">
                        {{trepinglang.speed}} words per day
                      </p>
                    </td> -->
                    <td class="mat-cell" style="border: none;">
                      <div *ngIf="trepinglang.flexible_rate!=null">
                        <p *ngIf='trepinglang.flexible_rate=="1"'>Yes</p>
                        <p *ngIf='trepinglang.flexible_rate=="0"'>No</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

                <mat-form-field fxFlex="40">

                  <mat-label>Speciality Fields*</mat-label>

                  <mat-select multiple formControlName="specialities">

                    <mat-option *ngFor="let speciality of specialityData" [value]="speciality.id">
                      {{speciality.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="Form.get('specialities').hasError('required')">
                    Speciality Fields is required!
                  </mat-error>
                </mat-form-field>
              </div>
              <h4><b> Skills: *</b></h4>
              <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                fxLayoutGap.lt-md="0px">
                <section class="section">
                    <mat-checkbox class="section-margin" [value]="1" [checked]="selected_skills.includes(1)"
                      (change)="Skills($event)">Translation</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="2" [checked]="selected_skills.includes(2)"
                      (change)="Skills($event)">Proofreading</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="3" [checked]="selected_skills.includes(3)"
                      (change)="Skills($event)">DTP</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="4" [checked]="selected_skills.includes(4)"
                      (change)="Skills($event)">QA</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="5" [checked]="selected_skills.includes(5)"
                      (change)="Skills($event)">Translation Mailing</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="6" [checked]="selected_skills.includes(6)"
                      (change)="Skills($event)">Notarization</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="7" [checked]="selected_skills.includes(7)"
                      (change)="Skills($event)">Apostile</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="8" [checked]="selected_skills.includes(8)"
                      (change)="Skills($event)">Subtitling</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="9" [checked]="selected_skills.includes(9)"
                      (change)="Skills($event)">Localization </mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="10" [checked]="selected_skills.includes(10)"
                      (change)="Skills($event)">Gaming</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="11" [checked]="selected_skills.includes(11)"
                      (change)="Skills($event)">Transcription</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="12" [checked]="selected_skills.includes(12)"
                      (change)="Skills($event)">Subtitling Embedding and Hardcoding</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="13" [checked]="selected_skills.includes(13)"
                      (change)="Skills($event)">Voice Over</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="78" [checked]="selected_skills.includes(78)"
                      (change)="Skills($event)">Terping</mat-checkbox>
                    <mat-checkbox class="section-margin" [value]="94" [checked]="selected_skills.includes(94)"
                      (change)="Skills($event)">Interpreting</mat-checkbox>
                </section>
              </div>
            </div>

            <h4>Technology</h4>
            <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-1" fxFlex="auto">
                <p class="dLabel">Tools using</p>
                <section class="section">
                  <p>
                    <mat-checkbox class="section-margin" value="WordFast"
                      [checked]="selected_tools.includes('WordFast')" (change)="tools($event)">WordFast</mat-checkbox>
                    <mat-checkbox class="section-margin" value="Trados" [checked]="selected_tools.includes('Trados')"
                      (change)="tools($event)">Trados</mat-checkbox>
                    <mat-checkbox class="section-margin" value="MemoQ" [checked]="selected_tools.includes('MemoQ')"
                      (change)="tools($event)">MemoQ</mat-checkbox>
                    <mat-checkbox class="section-margin" value="Phraseapp"
                      [checked]="selected_tools.includes('Phraseapp')" (change)="tools($event)">Phraseapp</mat-checkbox>
                    <mat-checkbox class="section-margin" value="Transifex"
                      [checked]="selected_tools.includes('Transifex')" (change)="tools($event)">Transifex</mat-checkbox>
                    <mat-checkbox class="section-margin" value="SmartCat"
                      [checked]="selected_tools.includes('SmartCat')" (change)="tools($event)">SmartCat</mat-checkbox>
                    <mat-checkbox class="section-margin" value="Other" [checked]="selected_tools.includes('Other')"
                      (change)="tools($event)">Other</mat-checkbox>
                  </p>
                </section>
              </div>
            </div>

            <!-- <p><b>License:</b> WordFast- Available</p> -->
            <div>
              <b> Valid license </b>
            </div>
            <div>
              <mat-radio-group formControlName="tool_licence">
                <mat-radio-button value="0">No</mat-radio-button>
                <mat-radio-button value="1">Yes</mat-radio-button>
                <mat-error *ngIf="Form.get('tool_licence').hasError('required')">
                  Tool licence Fields is required!
                </mat-error>
              </mat-radio-group>
            </div>



            <h4>Financials</h4>

            <div class="m-8">
              <b> Payment method (We prefer PayPal) :* </b>
            </div>
            <div>
              <mat-radio-group formControlName="payment_method">
                <mat-radio-button value="PayPal">PayPal</mat-radio-button>
                <mat-radio-button value="Payoneer">Payoneer</mat-radio-button>
                <mat-radio-button value="Skrill">Skrill</mat-radio-button>
                <mat-radio-button value="Other">Other</mat-radio-button>
                <mat-error *ngIf="Form.get('payment_method').hasError('required')">
                  Payment method Fields is required!
                </mat-error>
              </mat-radio-group>
            </div>
            <div  *ngIf="Form.get('payment_method').value=='Other'">
              <mat-form-field fxFlex="40">
                <mat-label class="font-size-12">Other Payment Method</mat-label>
                <input matInput formControlName="other_payment_method">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field fxFlex="40">
                <mat-label class="font-size-12">Payment method email</mat-label>
                <input matInput formControlName="payment_method_id">
                <mat-error *ngIf="Form.get('payment_method_id').hasError('email')">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <div fxFlex="40"><b>Do you use CAT (Computer Assisted Translation) tools? :</b> </div>
            <div fxFlex="60">
              <mat-radio-group formControlName="translation_tools">
                <mat-radio-button value="1">No</mat-radio-button>
                <mat-radio-button value="2">Yes</mat-radio-button>
                <mat-radio-button value="3">Yes, but I will not use them unless requested.</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div>
            <mat-form-field fxFlex="40">
              <mat-label class="font-size-12">Program/Software used for subtitler</mat-label>
              <input matInput formControlName="subtitles_program_software">
            </mat-form-field>
          </div>

          <h4>Days Available</h4>

          <section class="section">
            <mat-checkbox class="section-margin" value="Sunday" [checked]="selected_days.includes('Sunday')"
              (change)="Available_days($event)">Sunday</mat-checkbox>
            <mat-checkbox class="section-margin" value="Monday" [checked]="selected_days.includes('Monday')"
              (change)="Available_days($event)">Monday</mat-checkbox>
            <mat-checkbox class="section-margin" value="Tuesday" [checked]="selected_days.includes('Tuesday')"
              (change)="Available_days($event)">Tuesday</mat-checkbox>
            <mat-checkbox class="section-margin" value="Wednesday" [checked]="selected_days.includes('Wednesday')"
              (change)="Available_days($event)">Wednesday</mat-checkbox>
            <mat-checkbox class="section-margin" value="Thursday" [checked]="selected_days.includes('Thursday')"
              (change)="Available_days($event)">Thursday</mat-checkbox>
            <mat-checkbox class="section-margin" value="Friday" [checked]="selected_days.includes('Friday')"
              (change)="Available_days($event)">Friday</mat-checkbox>
            <mat-checkbox class="section-margin" value="Saturday" [checked]="selected_days.includes('Saturday')"
              (change)="Available_days($event)">Saturday</mat-checkbox>
          </section>

          <!-- <h4>Voice Sample</h4>
          <mat-form-field  class="d-full-width">
            <textarea matInput formControlName="voice_sample" >
            </textarea>
          </mat-form-field> -->

          <h4>Detailed cover letter</h4>
          <mat-form-field class="d-full-width">
            <textarea matInput formControlName="cover_letter"></textarea>

          </mat-form-field>

          <h4>Resume</h4>
          <p *ngIf="vendor_resume_name!=null">{{vendor_resume_name}} <a [href]="vendor_resume_data" target="_blank"
              download>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a></p>
          <button mat-stroked-button (click)="onResumeUpload()" *ngIf="response_data?.stage?.data?.name!='Approval'">
            <mat-icon>vertical_align_top</mat-icon>
          </button>
          <input type="file" id="resumeUpload" name="resumeUpload" style="display:none;" />
          <div>
            <mat-error>{{resumeError}}</mat-error>
            <span style="color: green;">{{resumeSuccess}}</span>
          </div>



          <h4>Contract</h4>

          <p *ngIf="vendor_contract_name!=null"> {{vendor_contract_name}} <a [href]="vendor_contract_data"
              target="_blank" download>
              <mat-icon>vertical_align_bottom</mat-icon>
            </a>
          </p>
          <button mat-stroked-button (click)="onContractUpload()" *ngIf="response_data?.stage?.data?.name!='Approval'">
            <mat-icon>vertical_align_top</mat-icon>
          </button>
          <input type="file" id="contractUpload" name="contractUpload" style="display:none;" />
          <div>
            <mat-error>{{contractError}}</mat-error>
            <span style="color: green;">{{contractSuccess}}</span>
          </div>

          <!-- <h4>Important Note</h4>
           <mat-form-field class="d-full-width">
               <textarea matInput  formControlName="important_note"  ></textarea>
             </mat-form-field> -->




        </div>





      </div>

      <div class="actWraper">


        <p>
          <button mat-raised-button color="accent" type="button" (click)="submit()"
            [disabled]="Form.invalid">Update</button>

        </p>
      </div>

    </div>
  </form>
</div>